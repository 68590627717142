@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

*{
    margin-top: 0;
    padding: 0;    
}

body{
    font-family: 'Open Sans', sans-serif;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 251, 251);
    overflow-x: hidden;
    max-width: 3200px;
    margin-left: auto;
    margin-right: auto; 
    text-align: justify;
    padding: 0px 10px;
}

main{
    max-width: 1200px;
    margin: 10px auto;
    margin-top: 20px;
}

section{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin: 10px auto;
}

colgroup {
    color: rgb(0, 0, 0);
}

footer{
    max-width: 3200px;
    background: rgb(95, 20, 20);
    border-top: 1px solid grey;
    margin-top: 40px;
}

header{
    max-width: 3200px;
    border-bottom: 1px solid grey;
}